body {
    background-color: #FDE9FF;
    font-family: 'Lato', sans-serif;
}

a {
    text-decoration: none;
    color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Mitr', sans-serif;
}

.page {
    min-height: 75vh;
}

.text-purple {
    color: #7100E1;
}

.text-purple-dark {
    color: #2A1A58;
}

p {
    color: #2A1A58;
    font-size: 20px;
}

nav {
    background: #ffffff;
    z-index: 2;
}

.nav-item {
    color: #000000;
    text-decoration: none;
}

.logo {
    height: 45px;
}

.btn-primary {
    background-color: #7100E1 !important;
    border-color: #7100E1 !important;
    color: #ffffff !important;
}

.navbar-nav .nav-link {
    color: #2A1A58;
    font-weight: 700;
    font-size: 18px !important;
}

.icon-1 {
    position: absolute;
    top: -50px;
    right: 50px;
}

.icon-1 img {
    height: 580px;
}

#intro {
    margin: 200px 0px 0px;
}

#intro p {
    font-size: 20px;
    font-weight: 600;
}

.intro-bottom {
    background-image: url('../src/Assets/graphics/intro-bottom.png');
    height: 200px;
    background-position: top;
    background-repeat: no-repeat;
}

.heading-addon {
    font-family: 'Satisfy', cursive;
    font-size: 40px;
    color: #6CCBFF;
}

.main-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #2A1A58;
}

.main-title h2 {
    font-size: 50px;
    margin-left: 31px;
    margin-top: -21px;
    margin-bottom: 0px;
}

.main-title h2 span {
    color: #7100E1;
}

.title-bottom {
    margin-top: -10px;
    margin-left: 350px;
}

.icon-2 {
    position: relative;
    top: -100px;
}

/* section 2 */
#section2 {
    background-image: url('../src/Assets/graphics/section-2.png');
    min-height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: -100px;
    padding: 200px 0px;
}

#section2 .description p {
    color: #ffffff;
    margin-bottom: 0px;
    font-size: 25px;
    font-weight: 600;
}

/* services */
#services {
    position: relative;
    top: -180px;
}

#services .accordion {
    background-color: transparent;
}

#services .accordion-item {
    background-color: transparent;
    border: none;
}

#services .accordion-button {
    background: #0dcaf0;
    color: #ffffff;
    height: 80px;
    padding: 15px 32px;
    text-align: center;
    display: inline-block;
    border-radius: 0px 0px 50px 50px;
    transform: skew(358deg);
}

#services .accordion-button[aria-expanded="true"] {
    border-radius: 50px 50px 0 0;
}

#services .blockchain .accordion-button {
    background: #FFAA1F;
}

#services .bespoke .accordion-button {
    background: #7100E1;
}

#services .contract .accordion-button {
    background: #2A1A58;
}

#services .web3.accordion-body {
    background: #0dcaf0;
}

#services .blockchain-body.accordion-body {
    background: #FFAA1F;
}

#services .bespoke-body.accordion-body {
    background: #7100E1;
}

#services .contract-body.accordion-body {
    background: #2A1A58;
}

.contract-body p {
    color: #ffffff;
    text-align: center;
}

.nft-box {
    border: 2px solid #ffffff;
    border-radius: 20px;
    padding: 50px 20px;
}

.contract-box {
    text-align: center;
    border: 2px solid #ffffff;
    border-radius: 25px;
    padding: 10px 0px;
    margin-bottom: 20px;
}

#why .icon-right {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    position: relative;
}

#why-content {
    position: relative;
    top: -100px;
    z-index: 2;
}

#clients {
    background-color: #7100E1;
    min-height: 500px;
}

.cta {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}